import { FormControl, InputLabel, MenuItem, Select } from "@material-ui/core";
import React, { useEffect, useState, useContext } from "react";

function SelectPod({
  formControl,
  mergedSeapodsData,
  seapodName,
  selectedPod,
  handleChangePod,
  handleClose,
}) {
  return (
    <FormControl variant='outlined' className={formControl}>
      <InputLabel id='select-pod-label'>
        {" "}
        {seapodName !== "" ? seapodName : "Select Pod"}
      </InputLabel>
      <Select
        id='select-pod'
        value={selectedPod}
        onChange={handleChangePod}
        label='pod'>
        {mergedSeapodsData.map((seapod) => (
          <MenuItem
            key={seapod.SeaPodID}
            value={seapod.SeaPodName}
            onClick={
              handleClose !== "createRoute" &&
              (() => handleClose(seapod.SeaPodID, seapod.SeaPodName))
            }>
            {seapod.SeaPodName}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

export default SelectPod;
