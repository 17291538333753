import React, { useContext, useEffect, useState, useRef } from "react";
import { Auth, API, graphqlOperation } from "aws-amplify";
import { onAddSeaPodData, onAddAlert } from "../graphql/subscription";
import { useHistory } from "react-router-dom";
import axios from "axios";
import { AuthContext } from "../AuthContext";
import Navbar from "./Navbar";
import { Container, Grid, Typography, useTheme } from "@material-ui/core";
import { useStyles } from "./styles/homeStyles";
import CustomDrawer from "./Drawer";
import PowerStored from "./PowerStored";
import WaterTankChart from "./WaterTankChart";
import WeatherWidget from "./WeatherWidget";
import HealthStatus from "./HealthStatus";
import OccupancyStatus from "./OccupancyStatus";
import AddSeaPod from "./AddSeaPod";
import SeaPodsList from "./SeaPodsList";
import PowerProduced from "./PowerProduced";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import _ from "lodash";
import ZoneMap from "./ZoneMap";
import Alerts from "./Alerts";
import DevicesList from "./DevicesList";
// import HealthChart from "./HealthChart";
import config from "../config.json";
import Preferences from "./Preferences";
import Communications from "./Communications";
import ManageDrones from "./ManageDrones";
function Alert(props) {
  return <MuiAlert elevation={6} variant='filled' {...props} />;
}

function Home() {
  const classes = useStyles();
  const history = useHistory();
  const theme = useTheme();
  const {
    stateForm,
    stateSidebar,
    stateUser,
    stateAllSeapods,
    stateMergedSeapodsData,
    stateGetSeaPodItems,
    stateNotificationCount,
    stateAlertType,
  } = useContext(AuthContext);
  const [formState, setFormState] = stateForm;
  const [sidebarState, setSidebarState] = stateSidebar;
  const [userDetails, setUserDetails] = stateUser;
  const [allSeapods, setAllSeapods] = stateAllSeapods;
  const [allSeapodsCopy, setAllSeapodsCopy] = useState({});
  const [alertData, setAlertData] = useState({});
  const [alertType, setAlertType] = stateAlertType;
  const [mergedSeapodsData, setMergedSeapodsData] = stateMergedSeapodsData;
  const [getSeapodsItems, setGetSeapodsItems] = stateGetSeaPodItems;
  const [notificationCount, setNotificationCount] = stateNotificationCount;
  const [dataFetched, setDataFetched] = useState(false);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [devices, setDevices] = useState([]);

  const count = useRef(0);
  setNotificationCount(count.current);

  if (dataFetched) {
    for (var seapod = 0; seapod <= allSeapods.length; seapod++) {
      if (allSeapods[seapod] !== undefined) {
        if (allSeapods[seapod].SeaPodID === getSeapodsItems["SeaPodID"]) {
          _.merge(allSeapodsCopy[seapod], getSeapodsItems);
        }
      }
    }
  }

  setMergedSeapodsData(allSeapodsCopy);

  const signout = async () => {
    try {
      await Auth.signOut();
      setFormState("signedOut");
    } catch (error) {
      console.log(error);
    }
  };

  async function subscribeToPodAlertsMutation() {
    const authToken = (await Auth.currentSession()).getIdToken().getJwtToken();
    const subscription = API.graphql({
      query: onAddAlert,
      authToken,
    }).subscribe({
      next: ({ provider, value }) => {
        count.current = count.current + 1;
        console.log(value);
        if (
          value.data.onAddAlert.DeviceID.includes("    ") &&
          value.data.onAddAlert.DeviceName.includes("    ") &&
          value.data.onAddAlert.Measurement.includes("    ")
        ) {
          const deviceID = value.data.onAddAlert.DeviceID.split("    ");
          const deviceName = value.data.onAddAlert.DeviceName.split("    ");
          const measurement = value.data.onAddAlert.Measurement.split("    ");
          let devicesList = [];
          deviceID.forEach((itm, index) => {
            let obj = {
              DeviceID: deviceID[index],
              DeviceName: deviceName[index],
              Measurement: measurement[index],
              AlertID: value.data.onAddAlert.AlertID,
              Type: value.data.onAddAlert.Type,
              SeaPodID: value.data.onAddAlert.SeaPodID,
              AlertStatus: value.data.onAddAlert.AlertStatus,
              Timestamp: value.data.onAddAlert.Timestamp,
            };
            // console.log(obj);
            devicesList.push(obj);
          });
          setShowSnackbar(true);
          setDevices(devicesList);
          setAlertType({
            SeaPodID: value.data.onAddAlert.SeaPodID,
            Type: value.data.onAddAlert.Type,
            AlertID: value.data.onAddAlert.AlertID,
          });
          // setMergedSeapodsData({...mergedSeapodsData, alertType:value.data.onAddAlert.Type})
        } else {
          setShowSnackbar(true);
          setAlertData(value.data.onAddAlert);
          setAlertType({
            SeaPodID: value.data.onAddAlert.SeaPodID,
            Type: value.data.onAddAlert.Type,
          });
          // console.log(mergedSeapodsData.filter(item=>item.SeaPodID===value.data.onAddAlert.SeaPodID));
          // setMergedSeapodsData({...mergedSeapodsData, alertType:value.data.onAddAlert.Type})
        }
      },
      error: (error) => console.warn(error),
    });

    // subscription.unsubscribe();
  }

  async function subscribeToPodDataMutation() {
    const authToken = (await Auth.currentSession()).getIdToken().getJwtToken();
    const subscription = API.graphql({
      query: onAddSeaPodData,
      authToken,
    }).subscribe({
      next: ({ provider, value }) => {
        // console.log({ provider, value });
        setGetSeapodsItems(value.data.onAddSeaPodData);
        setDataFetched(true);
      },
      error: (error) => console.warn(error),
    });
  }

  useEffect(() => {
    async function getSeaPodInfo() {
      const user = await Auth.currentAuthenticatedUser();
      setUserDetails(user);
      // console.log(user.signInUserSession.idToken);
      if (user.signInUserSession.idToken.payload.email_verified === false) {
        setFormState("emailNotVerified");
        history.push("/sendconfirmationcode");
      }
      // https://99iw2z8dah.execute-api.us-east-1.amazonaws.com/prod
      // https://yb4lptrfk9.execute-api.us-east-1.amazonaws.com/dev
      try {
        subscribeToPodDataMutation();
        subscribeToPodAlertsMutation();
        await axios
          .get("https://yb4lptrfk9.execute-api.us-east-1.amazonaws.com/dev", {
            headers: {
              "x-auth-token": user.signInUserSession.idToken.jwtToken,
            },
          })
          .then((res) => {
            res = res.data.seapods;
            setAllSeapods(JSON.parse(JSON.stringify(res)));
            setAllSeapodsCopy(JSON.parse(JSON.stringify(res)));
            // getFirstItemForEachPod(
            //   res,
            //   user.signInUserSession.idToken.jwtToken
            // );
          });
      } catch (error) {
        console.log(error);
      }
    }

    const getFirstItemForEachPod = (response, userIDToken) => {
      response.map(async (item) => {
        await axios
          .get(
            `https://yb4lptrfk9.execute-api.us-east-1.amazonaws.com/dev/seapod-data?id=${item["SeaPodID"]}`,
            {
              headers: {
                "x-auth-token": userIDToken,
              },
            }
          )
          .then((res) => {
            if (Object.keys(res.data).length > 0) {
              if (Object.keys(res.data).length === 1) {
                setGetSeapodsItems((prev) => [
                  ...prev,
                  res.data.seapod.Items[0],
                ]);
                setDataFetched(true);
              } else if (Object.keys(res.data).length > 1) {
                setGetSeapodsItems((prev) => [
                  ...prev,
                  res.data.seapod.Items[1],
                ]);
                setDataFetched(true);
              }
            }
          })
          .catch((err) => console.log(err));
      });
    };
    if (formState === "signedIn") {
      getSeaPodInfo();
    }
  }, []);

  function handleClose(event, reason) {
    if (reason === "clickaway") {
      return;
    }

    setShowSnackbar(false);
  }

  return (
    <div>
      {formState === "signedIn" ? (
        <>
          <Navbar logout={signout} />
          <Grid container className={classes.mainContainer}>
            <Grid item md={2}>
              <CustomDrawer logout={signout} />
            </Grid>
            {Object.keys(alertData).length !== 0 && devices.length === 0 && (
              <Snackbar open={showSnackbar} onClose={handleClose}>
                <Alert onClose={handleClose} severity={alertData.Type}>
                  {alertData.DeviceName} with DeviceID {alertData.DeviceID} in{" "}
                  Pod {alertData.SeaPodID} is working at {alertData.Measurement}
                </Alert>
              </Snackbar>
            )}
            {Object.keys(alertData).length !== 0 &&
              devices.length > 0 &&
              devices.map((item) => (
                <Snackbar open={showSnackbar} onClose={handleClose}>
                  <Alert onClose={handleClose} severity='error'>
                    {item.DeviceName} with DeviceID {item.DeviceID} in Pod{" "}
                    {item.SeaPodID} is working at {item.Measurement}
                  </Alert>
                </Snackbar>
              )) &&
              setDevices([])}

            {sidebarState === "dashboard" && (
              <>
                <Grid item xs={12} md={7} className={classes.homeContainer}>
                  <Container className={classes.container}>
                    <div className={classes.widgets}>
                      <div className={classes.weatherWidget}>
                        <WeatherWidget />
                      </div>
                      <div>
                        <HealthStatus />
                      </div>
                    </div>

                    <div
                      style={{
                        paddingBottom: "56.25%",
                        position: "relative",
                        height: 0,
                        marginBottom: theme.spacing(5),
                        border: "1px solid #d3d3d3",
                        borderRadius: ".5rem",
                        boxShadow: "0 2rem 2rem rgba(0,0,0,0.15)",
                      }}>
                      <div
                        style={{
                          position: "absolute",
                          top: "0",
                          left: "0",
                          width: "100%",
                          height: "100%",

                          display: "flex",
                          flexDirection: "column",
                          // justifyContent: "center",
                          alignItems: "center",
                          overflow: "hidden",
                        }}>
                        {Object.keys(allSeapodsCopy).length !== 0 && (
                          <PowerStored mergedData={allSeapodsCopy} />
                        )}
                      </div>
                    </div>

                    <div
                      style={{
                        paddingBottom: "56.25%",
                        position: "relative",
                        height: 0,
                        marginBottom: theme.spacing(5),
                        border: "1px solid #d3d3d3",
                        borderRadius: ".5rem",
                        boxShadow: "0 2rem 2rem rgba(0,0,0,0.15)",
                      }}>
                      <div
                        style={{
                          position: "absolute",
                          top: "0",
                          left: "0",
                          width: "100%",
                          height: "100%",

                          display: "flex",
                          flexDirection: "column",
                          // justifyContent: "center",
                          alignItems: "center",
                          overflow: "hidden",
                        }}>
                        {Object.keys(allSeapodsCopy).length !== 0 && (
                          <PowerProduced mergedData={allSeapodsCopy} />
                        )}
                      </div>
                    </div>

                    <div
                      style={{
                        paddingBottom: "56.25%",
                        position: "relative",
                        height: 0,
                        marginBottom: theme.spacing(5),
                        border: "1px solid #d3d3d3",
                        borderRadius: ".5rem",
                        boxShadow: "0 2rem 2rem rgba(0,0,0,0.15)",
                      }}>
                      <div
                        style={{
                          position: "absolute",
                          top: "0",
                          left: "0",
                          width: "100%",
                          height: "100%",

                          display: "flex",
                          flexDirection: "column",
                          // justifyContent: "center",
                          alignItems: "center",
                          overflow: "hidden",
                        }}>
                        {Object.keys(allSeapodsCopy).length !== 0 && (
                          <WaterTankChart mergedData={allSeapodsCopy} />
                        )}
                      </div>
                    </div>
                  </Container>
                </Grid>
                <Grid item xs={12} md={3} className={classes.homeContainer}>
                  <Container className={classes.container}>
                    <div
                    // style={{
                    //   paddingBottom: "100%",
                    //   position: "relative",
                    //   height: 0,
                    //   marginBottom: theme.spacing(5),
                    //   border: "1px solid #d3d3d3",
                    //   borderRadius: ".5rem",
                    //   // boxShadow: "0 2rem 2rem rgba(0,0,0,0.15)",
                    // }}
                    >
                      {/* <div
                        style={{
                          position: "absolute",
                          top: "0",
                          left: "0",
                          width: "100%",
                          height: "100%",

                          display: "flex",
                          flexDirection: "column",
                          // justifyContent: "center",
                          alignItems: "center",
                          overflow: "hidden",
                        }}> */}
                      <OccupancyStatus />
                      {/* </div> */}
                    </div>
                  </Container>
                </Grid>
              </>
            )}
            {sidebarState === "manage_seapods" && (
              <Grid item xs={12} md={10} className={classes.homeContainer}>
                <Container className={classes.container}>
                  <Typography variant='h4' style={{ fontWeight: 700 }}>
                    Pods
                  </Typography>
                  <SeaPodsList />
                </Container>
              </Grid>
            )}
            {sidebarState === "add_seapod" && (
              <Grid item xs={12} md={10} className={classes.homeContainer}>
                <Container className={classes.container}>
                  <AddSeaPod />
                </Container>
              </Grid>
            )}
            {sidebarState === "zone" && (
              <Grid item xs={12} md={10} className={classes.homeContainer}>
                <Container className={classes.container}>
                  <Typography variant='h4' style={{ fontWeight: 700 }}>
                    Zone Map
                  </Typography>
                  <ZoneMap />
                </Container>
              </Grid>
            )}
            {sidebarState === "devices" && (
              <Grid item xs={12} md={10} className={classes.homeContainer}>
                <Container className={classes.container}>
                  <Typography variant='h4' style={{ fontWeight: 700 }}>
                    Devices
                  </Typography>
                  <DevicesList />
                </Container>
              </Grid>
            )}
            {sidebarState === "alerts" && (
              <Grid item xs={12} md={7} className={classes.homeContainer}>
                <Container className={classes.container}>
                  <Typography variant='h4' style={{ fontWeight: 700 }}>
                    Alerts
                  </Typography>
                  <Alerts />
                </Container>
              </Grid>
            )}
            {sidebarState === "communication" && (
              <Grid item xs={12} md={10} className={classes.homeContainer}>
                <Container className={classes.container}>
                  <Typography variant='h4' style={{ fontWeight: 700 }}>
                    Notifications
                  </Typography>
                  <Communications />
                </Container>
              </Grid>
            )}
            {sidebarState === "manage_drones" && (
              <Grid item xs={12} md={10} className={classes.homeContainer}>
                <Container className={classes.container}>
                  <Typography variant='h4' style={{ fontWeight: 700 }}>
                    Manage Drones
                  </Typography>
                  <ManageDrones />
                </Container>
              </Grid>
            )}
            
            {sidebarState === "users" && (
              <Grid item xs={12} md={7} className={classes.homeContainer}>
                <Container className={classes.container}>
                  <Typography variant='h4' style={{ fontWeight: 700 }}>
                    Users
                  </Typography>
                  <div>TBD</div>
                </Container>
              </Grid>
            )}

            {sidebarState === "preferences" && (
              <Grid item xs={12} md={10} className={classes.homeContainer}>
                <Container className={classes.container}>
                  <Typography variant='h4' style={{ fontWeight: 700 }}>
                    Preferences
                  </Typography>
                  <Preferences />
                </Container>
              </Grid>
            )}
          </Grid>
        </>
      ) : (
        history.push("/login")
      )}
    </div>
  );
}

export default Home;
