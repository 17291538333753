import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
    container: {
      height: "100vh",
      color: "white",
      paddingTop: theme.spacing(10),
      backgroundColor: theme.palette.secondary.main,
      position: "sticky",
      top: 0,
      [theme.breakpoints.up("md")]: {
        marginTop: theme.spacing(3),
        backgroundColor: "white",
        color: theme.palette.secondary.main,
        border: "1px solid #ece7e7"
      },
    },
    mobileListItem: {
      [theme.breakpoints.up("md")]: {
        display: "none",
      },
    },
    item: {
    //   display: "flex",
    //   alignItems: "center",
      marginBottom: theme.spacing(4),
      [theme.breakpoints.up("md")]: {
        marginBottom: theme.spacing(3),
        cursor: "pointer",
      },
      '&:hover': {
        backgroundColor: theme.palette.secondary.main,
        color: "white",
      }
      
    },
    icon: {
      marginRight: theme.spacing(1),
      [theme.breakpoints.up("md")]: {
        fontSize: "18px",
      }
    },
    text: {
      fontWeight: 500,
      [theme.breakpoints.down("md")]: {
        // display: "none",
        fontWeight: 300,
      },
    },
  }));