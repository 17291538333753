import {
  Divider,
  List,
  ListItem,
  ListItemText,
  makeStyles,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import ReplayIcon from "@material-ui/icons/Replay";
import IconButton from "@material-ui/core/IconButton";
import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { AuthContext } from "../AuthContext";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    maxWidth: 720,
    color: "white",
  },
  alertMain: {
    backgroundColor: theme.palette.alerts.main,
  },
  alertLight: {
    backgroundColor: theme.palette.alerts.light,
  },
}));

const Alerts = () => {
  const { stateUser, stateNotificationCount, stateAlertType } = useContext(AuthContext);
  const [userDetails, setUserDetails] = stateUser;
  const [alertType, setAlertType] = stateAlertType;
  const [alertsList, setAlertsList] = useState([]);
  const classes = useStyles();
  const [notificationCount, setNotificationCount] = stateNotificationCount;
  setNotificationCount(0);
  // console.log(alertsList);

  async function handleRemoveAlert(alertID) {
    try {
      const findAlert = alertsList.filter(alert=>alert.AlertID===alertID);
      console.log(findAlert[0]);
      setAlertType({
        SeaPodID: findAlert[0].SeaPodID,
        AlertID: findAlert[0].AlertID,
        Type: "success"
      });
      await fetch(
        `https://yb4lptrfk9.execute-api.us-east-1.amazonaws.com/dev/alerts?alert_id=${alertID}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            "x-auth-token": userDetails.signInUserSession.idToken.jwtToken,
          },
        }
      ).then((res) => {
        setAlertsList(alertsList.filter((item) => item.AlertID !== alertID));
      });
    } catch (error) {
      console.log(error);
    }
  }

  function handleReloadAlerts() {
    setAlertsList([]);
    fetchAlerts();
  }

  async function fetchAlerts() {
    try {
      
      await axios
        .get(
          "https://yb4lptrfk9.execute-api.us-east-1.amazonaws.com/dev/alerts",
          {
            headers: {
              "x-auth-token": userDetails.signInUserSession.idToken.jwtToken,
            },
          }
        )
        .then((res) => {
          if (Object.keys(res.data).length !== 0) {
            // sorting response array with respect to Timestamp

            res.data.alerts.Items.forEach((item, index) => {
              // check if multiple devices are not working in optimum threshold at the same time
              if (
                item.DeviceID.includes("    ") &&
                item.DeviceName.includes("    ") &&
                item.Measurement.includes("    ")
              ) {
                const deviceID = item.DeviceID.split("    ");
                const deviceName = item.DeviceName.split("    ");
                const measurement = item.Measurement.split("    ");
                deviceID.forEach((itm, index) => {
                  let obj = {
                    DeviceID: deviceID[index],
                    DeviceName: deviceName[index],
                    Measurement: measurement[index],
                    AlertID: item.AlertID,
                    SeaPodID: item.SeaPodID,
                    AlertStatus: item.AlertStatus,
                    Timestamp: item.Timestamp,
                  };
                  // console.log(obj);
                  res.data.alerts.Items.push(obj);
                });
                res.data.alerts.Items.splice(index, 1); // remove the object which contains spaces with all devices which are not working fine
              }
            });
            res.data.alerts.Items.sort((a, b) => {
              a = new Date(JSON.parse(a.Timestamp));
              a = Math.floor(a / 1000); // Convert to UNIX timestamp
              b = new Date(JSON.parse(b.Timestamp));
              b = Math.floor(b / 1000);
              return b - a;
            });
            setAlertsList(res.data.alerts.Items);
            
          } else {
            setAlertsList([]);
          }
        });
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    
    fetchAlerts();
  }, []);

  return (
    <div className={classes.root}>
      <IconButton
        onClick={handleReloadAlerts}
        color='primary'
        aria-label='Reload Alerts'
        component='span'>
        <ReplayIcon />
      </IconButton>
      <List component='nav'>
        {Object.keys(alertsList).length !== 0 &&
          alertsList.map((alert) => (
            <div key={alert.AlertID}>
              <ListItem className={alert.Type==="error"?classes.alertMain:alert.Type==="warning"&&classes.alertLight}>
                <ListItemText
                  primary={`${alert.DeviceName} in Pod ${alert.SeaPodID} is working at ${alert.Measurement}.`}
                />
                <IconButton
                  onClick={() => handleRemoveAlert(alert.AlertID)}
                  //   color='primary'
                  aria-label='Remove Alert'
                  component='span'>
                  <CloseIcon />
                </IconButton>
              </ListItem>
              <Divider />
            </div>
          ))}
      </List>
    </div>
  );
};

export default Alerts;
