import React, { useEffect, useState, useContext } from "react";
import CreateRoute from "./CreateRoute";
import { makeStyles } from "@material-ui/core/styles";
import { w3cwebsocket as W3CWebSocket } from "websocket";
import { Icon } from "leaflet";
import {
  MapContainer,
  TileLayer,
  Marker,
  Polyline,
  Tooltip,
} from "react-leaflet";
import { AuthContext } from "../AuthContext";
import Drone from "../images/drone.png";
import Home from "../images/home.png";
import GpsFixedIcon from "@material-ui/icons/GpsFixed";
import GpsOffIcon from "@material-ui/icons/GpsOff";
import { Button, Collapse, IconButton } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import CloseIcon from "@material-ui/icons/Close";
import AddLocationIcon from "@material-ui/icons/AddLocation";
import SelectDrone from "./SelectDrone";
import config from "../config.json";
import { hashPassword } from "../helper/hashFunction";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 140,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  button: {
    padding: "16px",
    margin: theme.spacing(1),
  },
  selectOptions: {
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      // alignItems: "center",
      // justifyContent: "center",
      flexDirection: "column",
    },
  },
}));

function ManageDrones() {
  const classes = useStyles();
  const [droneIDs, setDroneIDs] = useState(null);
  const [selectedDrone, setSelectedDrone] = useState("");
  const [selectedPod, setSelectedPod] = useState("");
  const { stateAllSeapods, statePodDevices, stateMergedSeapodsData } =
    useContext(AuthContext);
  const [allSeapods, setAllSeapods] = stateAllSeapods;
  const [mergedSeapodsData, setMergedSeapodsData] = stateMergedSeapodsData;
  const [seapodID, setSeapodID] = useState("");
  const [seapodName, setSeapodName] = useState("");
  const [error, setError] = useState({
    text: "",
    severity: "",
  });
  const [open, setOpen] = useState(false);
  const [openAlertBox, setOpenAlertBox] = useState(false);
  const [markerPositions, setMarkerPositions] = useState([]);
  const [droneMarkerPositions, setDroneMarkerPositions] = useState([
    9.613238511460384, -79.5787693197187,
  ]);
  const [stopTracking, setStopTracking] = useState(false);
  const [ws, setWs] = useState(null);
  const [distance, setDistance] = useState(null);
  const [speed, setSpeed] = useState(null);
  const [eta, setETA] = useState({
    hours: null,
    mins: null,
  });
  const [hash, setHash] = useState(null);

  const droneIcon = new Icon({
    iconUrl: Drone,
    iconSize: [45, 45],
  });
  const homeIcon = new Icon({
    iconUrl: Home,
    iconSize: [45, 45],
  });

  const handleChangeDrone = (event) => {
    setSelectedDrone(event.target.value);
  };

  const handleChangePod = (event) => {
    setSelectedPod(event.target.value);
  };

  const handleCreateButton = () => {
    setOpen(true);
  };

  const handleClose = (id, name) => {
    setSeapodID(id);
    setSeapodName(name);
    let filteredPod = mergedSeapodsData.filter((pod) => pod.SeaPodID === id);
    console.log(filteredPod);
    if (filteredPod[0].Latitude || filteredPod[0].Longitude !== undefined) {
      setMarkerPositions([
        filteredPod[0].Latitude,
        filteredPod[0].Longitude,
        filteredPod[0].SeaPodName,
        filteredPod[0].SeaPodID,
      ]);
    } else {
      setOpenAlertBox(true);
      setError({
        text: "Location coordinates not available!",
        severity: "error",
      });
    }
  };

  const handleTrackingButton = async () => {
    if (ws === null && stopTracking === false) {
      setStopTracking(true);
      const websocket = new W3CWebSocket(
        `wss://h0268o0vei.execute-api.us-east-1.amazonaws.com/dev?token=${hash}&drone_id=${selectedDrone}`
      );
      setWs(websocket);

      // ws.onopen = () => {
      //   console.log("connection established!");
      //   ws.send(JSON.stringify({ action: "senddata", data:{"drone_id": selectedDrone} }));
      // };
      websocket.onopen = () => {
        websocket.send(
          JSON.stringify({
            action: "fetchflightroute",
            data: { drone_id: selectedDrone },
          })
        );
      };

      websocket.onmessage = (event) => {
        console.log(event);
        let parsedData = JSON.parse(event.data);

        // if (event.data.includes("Route not found")) {
        //   setStopTracking(false);
        //   setOpenAlertBox(true);
        //   setError({
        //     text: "Route Not Found!",
        //     severity: "error",
        //   });
        // }
        if ("error" in parsedData) {
          if (parsedData.error.includes("Route not found")) {
            setStopTracking(false);
            setOpenAlertBox(true);
            setError({
              text: "Route Not Found!",
              severity: "error",
            });
          }
          if ((parsedData.error = "Disconnecting")) {
            console.log("disconnecting...");
            setStopTracking(false);
            setOpenAlertBox(true);
            websocket.close();
          }
        }
        if (parsedData.FlightStatus !== "COMPLETED") {
          if (
            parsedData.Dest_Lon !== undefined &&
            parsedData.Dest_Lat !== undefined
          ) {
            setMarkerPositions([parsedData.Dest_Lat, parsedData.Dest_Lon]);
          }
          if (parsedData.lat !== undefined && parsedData.lng !== undefined) {
            setDroneMarkerPositions([parsedData.lat, parsedData.lng]);
          }
          if (parsedData.speed !== undefined) {
            setSpeed(parsedData.speed);
          }
        } else {
        }
      };

      websocket.onclose = (event) => {
        console.log("connection closed!");
      };
    }

    if (ws !== null && stopTracking === true) {
      ws.close();
      setWs(null);
      setStopTracking(false);
    }
  };

  function coordinatesToDistance(lat1, lon1, lat2, lon2) {
    var R = 6371; // km
    var dLat = toRad(lat2 - lat1);
    var dLon = toRad(lon2 - lon1);
    var lat1 = toRad(lat1);
    var lat2 = toRad(lat2);

    var a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.sin(dLon / 2) * Math.sin(dLon / 2) * Math.cos(lat1) * Math.cos(lat2);
    var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    var d = R * c;
    return d;
  }

  // Converts numeric degrees to radians
  function toRad(Value) {
    return (Value * Math.PI) / 180;
  }

  useEffect(() => {
    if (stopTracking === true) {
      let d = coordinatesToDistance(
        markerPositions[0],
        markerPositions[1],
        droneMarkerPositions[0],
        droneMarkerPositions[1]
      );
      setDistance(d.toFixed(2));
      let hours = d.toFixed(2) / speed;
      let mins = hours * 60;
      setETA({ hours: hours.toFixed(), mins: mins.toFixed() });
    }
  }, [markerPositions, droneMarkerPositions]);

  useEffect(() => {
    let hash = hashPassword(
      config.drone_tracking_websockets_api_aws_secret_access_key
    );
    console.log(hash);
    setHash(hash);
    const ws = new W3CWebSocket(
      `wss://h0268o0vei.execute-api.us-east-1.amazonaws.com/dev?token=${hash}`
    );
    ws.onerror = (e) => {
      console.log(e);
    };

    ws.onopen = () => {
      console.log("connection established!");
      ws.send(JSON.stringify({ action: "fetchalldrones" }));
    };

    ws.onmessage = (event) => {
      console.log(event);
      if (event.data !== undefined) {
        setDroneIDs(JSON.parse(event.data));
      } else {
        setDroneIDs(null);
      }
    };

    setTimeout(() => {
      ws.close();
    }, 5000);

    ws.onclose = (event) => {
      console.log("connection closed!");
    };
  }, []);

  return (
    <div className={classes.selectOptions}>
      {error.text !== "" && (
        <Collapse in={openAlertBox}>
          <Alert
            variant='filled'
            severity={error.severity}
            style={{ margin: "10px 0" }}
            action={
              <IconButton
                aria-label='close'
                color='inherit'
                size='small'
                onClick={() => {
                  setOpenAlertBox(false);
                }}>
                <CloseIcon fontSize='inherit' />
              </IconButton>
            }>
            {error.text}
          </Alert>
        </Collapse>
      )}
      <div
      // style={{
      //   display: "flex",
      //   alignItems: "center",
      // }}
      >
        {/* <FormControl
          variant='outlined'
          className={classes.formControl}>
          <InputLabel id='select-drone-label'>Select Drone</InputLabel>
          <Select
            id='select-drone'
            value={selectedDrone}
            onChange={handleChangeDrone}
            label='drone'>
            <MenuItem value=''>
              <em>None</em>
            </MenuItem>
            {droneIDs !== null &&
              droneIDs.map((drone) => (
                <MenuItem key={drone.droneId} value={drone.droneId}>
                  {drone.droneId}
                </MenuItem>
              ))}
          </Select>
        </FormControl> */}
        <SelectDrone
          selectedDrone={selectedDrone}
          handleChangeDrone={handleChangeDrone}
          droneIDs={droneIDs}
          formControl={classes.formControl}
        />
        {/* <SelectPod
          formControl={classes.formControl}
          selectedPod={selectedPod}
          handleChangePod={handleChangePod}
          mergedSeapodsData={mergedSeapodsData}
          seapodName={seapodName}
          handleClose={handleClose}
        /> */}
        {/* <FormControl
          // ref={selectDroneComponent}
          variant='outlined'
          className={classes.formControl}>
          <InputLabel id='select-pod-label'>
            {" "}
            {seapodName !== "" ? seapodName : "Select Pod"}
          </InputLabel>
          <Select
            id='select-pod'
            value={selectedPod}
            onChange={handleChangePod}
            label='pod'>
            {allSeapods.map((seapod) => (
              <MenuItem
                key={seapod.SeaPodID}
                value={seapod.SeaPodName}
                onClick={() => handleClose(seapod.SeaPodID, seapod.SeaPodName)}>
                {seapod.SeaPodName}
              </MenuItem>
            ))}
          </Select>
        </FormControl> */}
        {stopTracking === false ? (
          <Button
            variant='contained'
            color='primary'
            startIcon={<GpsFixedIcon />}
            className={classes.button}
            onClick={handleTrackingButton}
            disabled={selectedDrone === ""}>
            Start Tracking
          </Button>
        ) : (
          <Button
            variant='contained'
            color='primary'
            startIcon={<GpsOffIcon />}
            className={classes.button}
            onClick={handleTrackingButton}>
            Stop Tracking
          </Button>
        )}
        <Button
          variant='contained'
          color='primary'
          startIcon={<AddLocationIcon />}
          className={classes.button}
          onClick={handleCreateButton}>
          Create Route
        </Button>
        <CreateRoute
          open={open}
          setOpen={setOpen}
          droneIDs={droneIDs}
          mergedSeapodsData={mergedSeapodsData}
          seapodName={seapodName}
          setError={setError}
          setOpenAlertBox={setOpenAlertBox}
          hash={hash}
        />
      </div>

      <MapContainer
        center={[9.613238511460384, -79.5787693197187]}
        zoom={18}
        scrollWheelZoom={true}>
        <TileLayer
          url='https://api.maptiler.com/maps/outdoor/{z}/{x}/{y}.png?key=6nhNTguvAFCDhpDwtXVh'
          tileSize={512}
          zoomOffset={-1}
          minZoom={1}
          attribution='\u003ca href=\"https://www.maptiler.com/copyright/\" target=\"_blank\"\u003e\u0026copy; MapTiler\u003c/a\u003e \u003ca href=\"https://www.openstreetmap.org/copyright\" target=\"_blank\"\u003e\u0026copy; OpenStreetMap contributors\u003c/a\u003e'
          crossOrigin={true}
        />
        {markerPositions.length !== 0 && (
          <Marker
            key={selectedPod}
            position={[markerPositions[0], markerPositions[1]]}
            icon={homeIcon}
          />
        )}
        {selectedDrone !== "" && (
          <Marker
            key={selectedDrone}
            position={droneMarkerPositions}
            icon={droneIcon}>
            {distance !== null && speed !== null && (
              <Tooltip>
                Distance Remaining: {distance} km ETA:{" "}
                {eta.hours !== null && eta.hours !== 0 && eta.hours} hours{" "}
                {eta.mins !== null && eta.mins !== 0 && eta.mins} mins
              </Tooltip>
            )}
          </Marker>
        )}
        {selectedDrone !== "" && markerPositions.length !== 0 && (
          <Polyline
            color='yellow'
            weight={3}
            smoothFactor={1}
            positions={[
              droneMarkerPositions,
              [markerPositions[0], markerPositions[1]],
            ]}
          />
        )}
      </MapContainer>
    </div>
  );
}

export default ManageDrones;
