import React, { useEffect, useState, useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Link from "@material-ui/core/Link";
import { Button, Collapse, IconButton } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import AddBoxIcon from "@material-ui/icons/AddBox";
import AddNewNotification from "./AddNewNotification";
import CloseIcon from "@material-ui/icons/Close";
import { AuthContext } from "../AuthContext";
import axios from "axios";
import TablePaginationActions from "./TablePaginationActions";
import PropTypes from "prop-types";
import TablePagination from "@material-ui/core/TablePagination";

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

function createData(
  notificationID,
  title,
  message,
  imgUrl,
  action,
  // deliveredTo,
  date,
  publishedBy
) {
  return {
    notificationID,
    title,
    message,
    imgUrl,
    action,
    // deliveredTo,
    date,
    publishedBy,
  };
}

let rows = [];

export default function Communications() {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [openAlertBox, setOpenAlertBox] = useState(true);
  const { stateUser, stateAllSeapods } = useContext(AuthContext);
  const [allSeapods, setAllSeapods] = stateAllSeapods;
  const [userDetails, setUserDetails] = stateUser;
  const [alertBox, setAlertBox] = useState({
    text: "",
    severity: "",
  });
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [lastEvaluatedKey, setLastEvaluatedKey] = useState(null);

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    if (lastEvaluatedKey !== undefined) {
      getNotifications();
    }
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const getNotifications = async () => {
    try {
      const res = await axios.get(
        "https://yb4lptrfk9.execute-api.us-east-1.amazonaws.com/dev/notification",
        {
          headers: {
            "x-auth-token": userDetails.signInUserSession.idToken.jwtToken,
          },
          params: {
            LastEvaluatedKey: lastEvaluatedKey,
          },
        }
      );
      console.log(res);
      setLastEvaluatedKey(res.data.notifications.LastEvaluatedKey);
      // setNotifications(res.data.notifications)''
      let sortedRes = res.data.notifications.Items.sort((a, b) => {
        a = new Date(JSON.parse(a.Timestamp));
        a = Math.floor(a / 1000); // Convert to UNIX timestamp
        b = new Date(JSON.parse(b.Timestamp));
        b = Math.floor(b / 1000);
        return b - a;
      });
      sortedRes.map((noti) => {
        var timeConvert = new Date(JSON.parse(noti.Timestamp));
        var podIDtoName = [];
        // convert SeaPod ID to SeaPod Name
        // noti.DeliveredTo.map((id) =>
        //   podIDtoName.push(
        //     allSeapods.filter((pod) => pod.SeaPodID === id)[0]["SeaPodName"]
        //   )
        // );
        rows.push(
          createData(
            noti.NotificationID,
            noti.Title,
            noti.Message,
            noti.FileURL,
            noti.ActionURL,
            // podIDtoName.toString(),
            timeConvert.toUTCString(),
            noti.PublishedBy
          )
        );
      });
    } catch (error) {
      console.log(error);
      setOpenAlertBox(true);
      setAlertBox({
        text: "Data fetching failed!",
        severity: "error",
      });
    }
  };

  useEffect(() => {
    rows = [];
    getNotifications();
  }, []);

  return (
    <div>
      <Button
        variant='contained'
        color='primary'
        startIcon={<AddBoxIcon />}
        style={{ marginTop: "10px", marginBottom: "10px" }}
        onClick={handleClickOpen}>
        Add New Notification
      </Button>
      <AddNewNotification
        open={open}
        setOpen={setOpen}
        setOpenAlertBox={setOpenAlertBox}
        setAlertBox={setAlertBox}
      />
      {alertBox.text !== "" && (
        <Collapse in={openAlertBox}>
          <Alert
            variant='filled'
            severity={alertBox.severity}
            style={{ marginBottom: "5px" }}
            action={
              <IconButton
                aria-label='close'
                color='inherit'
                size='small'
                onClick={() => {
                  setOpenAlertBox(false);
                }}>
                <CloseIcon fontSize='inherit' />
              </IconButton>
            }>
            {alertBox.text}
          </Alert>
        </Collapse>
      )}

      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label='simple table'>
          <TableHead>
            <TableRow>
              <TableCell>Title</TableCell>
              <TableCell>Message</TableCell>
              <TableCell align='center'>Attached Content</TableCell>
              <TableCell align='center'>Action URL</TableCell>
              {/* <TableCell align='center'>Delivered to</TableCell> */}
              <TableCell align='center'>Date Published</TableCell>
              <TableCell align='center'>Published By</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.length !== 0 &&
              (rowsPerPage > 0
                ? rows.slice(
                    page * rowsPerPage,
                    page * rowsPerPage + rowsPerPage
                  )
                : rows
              ).map((row) => (
                <TableRow key={row.notificationID}>
                  <TableCell component='th' scope='row'>
                    <b>{row.title}</b>
                  </TableCell>
                  <TableCell component='th' scope='row'>
                    {row.message}
                  </TableCell>
                  <TableCell align='center'>
                    {row.imgUrl !== undefined && (
                      <Link
                        href={row.imgUrl}
                        target='_blank'
                        rel='noopener noreferrer'
                        variant='body2'>
                        View
                      </Link>
                    )}
                  </TableCell>
                  <TableCell align='center'>{row.action}</TableCell>
                  {/* <TableCell align='center'>{row.deliveredTo}</TableCell> */}
                  <TableCell align='center'>{row.date}</TableCell>
                  <TableCell align='center'>{row.publishedBy}</TableCell>
                </TableRow>
              ))}
            {emptyRows > 0 && (
              <TableRow style={{ height: 53 * emptyRows }}>
                <TableCell colSpan={6} />
              </TableRow>
            )}
          </TableBody>
          {/* <TableFooter>
            <TableRow>
              
            </TableRow>
          </TableFooter> */}
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25, { label: "All", value: -1 }]}
        colSpan={3}
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        SelectProps={{
          inputProps: { "aria-label": "rows per page" },
          native: true,
        }}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        ActionsComponent={TablePaginationActions}
      />
    </div>
  );
}
