import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";
import React, { useState } from "react";
import SelectDrone from "./SelectDrone";
import SelectPod from "./SelectPod";
import { makeStyles } from "@material-ui/core/styles";
import { w3cwebsocket as W3CWebSocket } from "websocket";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 140,
  },
  selectBoxes: {
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      flexDirection: "column",
    },
  },
}));

function CreateRoute({
  open,
  setOpen,
  droneIDs,
  mergedSeapodsData,
  seapodName,
  setError,
  setOpenAlertBox,
  hash
}) {
  const classes = useStyles();

  const [selectedPod, setSelectedPod] = useState("");
  const [selectedDrone, setSelectedDrone] = useState("");
  const [droneRouteData, setDroneRouteData] = useState("");

  const dronePosition = [9.613238511460384, -79.5787693197187];

  function handleClose() {
    setOpen(false);
  }

  const handleChangeDrone = (event) => {
    setSelectedDrone(event.target.value);
  };

  const handleChangePod = (event) => {
    setSelectedPod(event.target.value);
  };

  function handleSubmit() {
    if (selectedPod && selectedDrone !== "") {
      let pod = mergedSeapodsData.filter(
        (pod) => pod.SeaPodName === selectedPod
      );
      let routeData = {
        DroneID: selectedDrone,
        PodID: pod[0].SeaPodID,
        Destination: { Dest_Lat: pod[0].Latitude, Dest_Lon: pod[0].Longitude },
        Origin: { Orig_Lat: dronePosition[0], Orig_Lon: dronePosition[1] },
      };
      console.log(routeData);

      if (
        routeData.Destination.Dest_Lat !== undefined &&
        routeData.Destination.Dest_Lon !== undefined
      ) {
        const ws = new W3CWebSocket(
          `wss://h0268o0vei.execute-api.us-east-1.amazonaws.com/dev?token=${hash}`
        );

        ws.onopen = () => {
          console.log("connection established. [CreateRoute]");
          ws.send(
            JSON.stringify({ action: "adddroneflightroute", data: routeData })
          );
          setOpen(false);
          setSelectedDrone("");
          setSelectedPod("");
        };

        ws.onmessage = (event) => {
          const parsedData = JSON.parse(event.data);
          setDroneRouteData(parsedData);
          if (parsedData.includes("Error")) {
            setError({
              severity: "error",
              text: parsedData,
            });
            setOpenAlertBox(true);
          }
        };

        setTimeout(() => {
          ws.close();
        }, 5000);

        ws.onclose = (event) => {
          console.log("connection closed! [CreateRoute]");
        };
      }else{
        setError({severity: "error", text: "Destination coornidates not found!"})
      }
    }
  }
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby='form-dialog-title'>
      <DialogTitle id='form-dialog-title'>New Route</DialogTitle>
      <form>
        <DialogContent>
          <div className={classes.selectBoxes}>
            <SelectDrone
              selectedDrone={selectedDrone}
              handleChangeDrone={handleChangeDrone}
              droneIDs={droneIDs}
              formControl={classes.formControl}
            />
            <SelectPod
              formControl={classes.formControl}
              selectedPod={selectedPod}
              handleChangePod={handleChangePod}
              mergedSeapodsData={mergedSeapodsData}
              seapodName={seapodName}
              handleClose='createRoute'
            />
          </div>
        </DialogContent>

        <DialogActions>
          <Button onClick={handleClose} color='primary'>
            Cancel
          </Button>
          <Button onClick={handleSubmit} color='primary'>
            Create
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}

export default CreateRoute;
