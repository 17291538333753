import React, { useContext } from "react";
import { Card, Tooltip, Typography, useTheme } from "@material-ui/core";
import CardContent from "@material-ui/core/CardContent";
import Avatar from "@material-ui/core/Avatar";
import { makeStyles } from "@material-ui/core/styles";
import active from "../images/active.png";
import inactive from "../images/inactive.png";
import uninitialized from "../images/uninitialized.png";
import { red, green, grey } from "@material-ui/core/colors";
import { AuthContext } from "../AuthContext";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  content: {
    display: "flex",
    // justifyContent: "space-around",
  },

  uninitiated: {
    width: theme.spacing(7),
    height: theme.spacing(7),
    color: "white",
    backgroundColor: grey[500],
  },
  inactive: {
    width: theme.spacing(7),
    height: theme.spacing(7),
    color: "white",
    backgroundColor: red[500],
  },
  active: {
    width: theme.spacing(7),
    height: theme.spacing(7),
    color: "white",
    backgroundColor: green[500],
  },
}));

function HealthStatus() {
  const classes = useStyles();
  const { stateMergedSeapodsData } = useContext(AuthContext);
  const [mergedSeapodsData, setMergedSeapodsData] = stateMergedSeapodsData;
  const theme = useTheme();

  return (
    <Card
      style={{ backgroundColor: theme.palette.cards.main, color: "white" }}
      variant='outlined'>
      <CardContent>
        <Typography variant='h6' component='h2'>
          Occupancy Status
        </Typography>
        <div
          id='ho-status'
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-around",
            // marginTop: theme.spacing(2),
          }}>
          {Object.keys(mergedSeapodsData).length !== 0 &&
            mergedSeapodsData.map((item) => (
              <Tooltip title={item["OccupancyStatus"]}>
                <Avatar
                  alt={item.SeaPodID}
                  src={
                    item["OccupancyStatus"] === "uninitialized"
                      ? uninitialized
                      : item["OccupancyStatus"] === "inactive"
                      ? inactive
                      : item["OccupancyStatus"] === "active" && active
                  }
                  className={
                    item["OccupancyStatus"] === "uninitialized"
                      ? classes.uninitiated
                      : item["OccupancyStatus"] === "inactive"
                      ? classes.inactive
                      : item["OccupancyStatus"] === "active" && classes.active
                  }
                  style={{ cursor: "pointer" }}
                />
              </Tooltip>
            ))}
        </div>
      </CardContent>
    </Card>
  );
}

export default HealthStatus;
