import { Button, TextField, Typography, useTheme } from "@material-ui/core";
import React, { useContext, useEffect, useState } from "react";
import { useStyles } from "./styles/styles";
import { v4 as uuidv4 } from "uuid";
import axios from "axios";
import { AuthContext } from "../AuthContext";

function AddNewDevice({ id, setOpen, setError }) {
  const deviceId = uuidv4();
  const [deviceName, setDeviceName] = useState(0);
  const [place, setPlace] = useState(null);
  const [empty, setEmpty] = useState(0);
  const [full, setFull] = useState(0);
  const [LOT, setLOT] = useState(0);
  const [UOT, setUOT] = useState(0);
  const [LT, setLT] = useState(0);
  const [UT, setUT] = useState(0);
  const classes = useStyles();
  const { stateUser, stateAllSeapods } = useContext(AuthContext);
  const [userDetails, setUserDetails] = stateUser;
  const theme = useTheme();

  const addDevice = async (event) => {
    event.preventDefault();
    const obj = JSON.stringify({
      SeaPodID: id,
      DeviceID: deviceId,
      DeviceName: deviceName,
      Empty: empty,
      Full: full,
      LowerOptimumThreshold: LOT,
      UpperOptimumThreshold: UOT,
      UpperTrigger: UT,
      LowerTrigger: LT,
      Place: place,
    });
    console.log(obj);
    await axios
      .post(
        "https://yb4lptrfk9.execute-api.us-east-1.amazonaws.com/dev/seapod-device",
        obj,
        {
          headers: {
            "x-auth-token": userDetails.signInUserSession.idToken.jwtToken,
          },
        }
      )
      .then((res) => {
        setError({ text: "Saved Device Info!", severity: "success" });
        console.log(res);
      })
      .catch((err) => {
        // console.log(err);
        setError({ text: err, severity: "error" });
      });
  };
  useEffect(() => {
    setOpen(true);
  }, []);

  return (
    <div>
      <Typography
        variant="h5"
        style={{
          fontWeight: 700,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        Add New Device
      </Typography>
      <form className={classes.form} onSubmit={addDevice} autoComplete="off">
        <div className={classes.textField}>
          <TextField
            id="device-name"
            label="Device Name"
            variant="outlined"
            size="small"
            required
            className={classes.textFieldInput}
            InputProps={{ className: classes.input }}
            onChange={(e) => setDeviceName(e.target.value)}
          />
        </div>

        <div className={classes.textField}>
          <TextField
            id="place"
            label="Place"
            variant="outlined"
            size="small"
            required
            className={classes.textFieldInput}
            InputProps={{ className: classes.input }}
            onChange={(e) => setPlace(e.target.value)}
          />
        </div>

        <div className={classes.textField}>
          <TextField
            id="min"
            label="Minimum value"
            variant="outlined"
            size="small"
            type="number"
            className={classes.textFieldInput}
            InputProps={{ className: classes.input }}
            onChange={(e) => setEmpty(e.target.value)}
          />
        </div>
        <div className={classes.textField}>
          <TextField
            id="max"
            label="Maximum Value"
            variant="outlined"
            size="small"
            type="number"
            className={classes.textFieldInput}
            InputProps={{ className: classes.input }}
            onChange={(e) => setFull(e.target.value)}
          />
        </div>
        <div className={classes.textField}>
          <TextField
            id="lower-optimum-threshold"
            label="Lower Optimum Threshold"
            variant="outlined"
            size="small"
            type="number"
            className={classes.textFieldInput}
            InputProps={{ className: classes.input }}
            onChange={(e) => setLOT(e.target.value)}
          />
        </div>
        <div className={classes.textField}>
          <TextField
            id="upper-optimum-threshold"
            label="Upper Optimum Threshold"
            variant="outlined"
            size="small"
            type="number"
            className={classes.textFieldInput}
            InputProps={{ className: classes.input }}
            onChange={(e) => setUOT(e.target.value)}
          />
        </div>
        <div className={classes.textField}>
          <TextField
            id="lower-trigger"
            label="Lower Trigger"
            variant="outlined"
            size="small"
            type="number"
            className={classes.textFieldInput}
            InputProps={{ className: classes.input }}
            onChange={(e) => setLT(e.target.value)}
          />
        </div>
        <div className={classes.textField}>
          <TextField
            id="upper-trigger"
            label="Upper Trigger"
            variant="outlined"
            size="small"
            type="number"
            className={classes.textFieldInput}
            InputProps={{ className: classes.input }}
            onChange={(e) => setUT(e.target.value)}
          />
        </div>

        {/* {success !== "" && (
          <div style={{ marginBottom: theme.spacing(2) }}>
            <Alert severity='success'>{success}</Alert>
          </div>
        )}
        {error !== "" && (
          <div style={{ marginBottom: theme.spacing(2) }}>
            <Alert severity='error'>{error}</Alert>
          </div>
        )} */}

        <div className={classes.firstNextButton}>
          <Button
            id="submit"
            type="submit"
            variant="contained"
            color="primary"
            style={{ borderRadius: "10px" }}
          >
            Add!
          </Button>
        </div>
      </form>
    </div>
  );
}

export default AddNewDevice;
