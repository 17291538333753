import React, { useContext, useEffect, useState } from "react";
import {
  FormControl,
  MenuItem,
  Select,
  TextField,
  Typography,
  InputLabel,
  Button,
  useTheme,
} from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import { useStyles } from "./styles/styles";
import axios from "axios";
import { AuthContext } from "../AuthContext";
import { v4 as uuidv4 } from "uuid";

function Alert(props) {
  return <MuiAlert elevation={6} variant='filled' {...props} />;
}

function AddSeaPod() {
  const classes = useStyles();
  const theme = useTheme();
  const seapodId = uuidv4();
  const [seapodName, setSeapodName] = useState("");
  const [occupancyState, setOccupancyState] = useState("");
  const [remoteURL, setRemoteURL] = useState("");
  const [zoneName, setZoneName] = useState("");
  const [ownedBy, setOwnedBy] = useState("");
  const [users, setUsers] = useState([]);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const { stateUser } = useContext(AuthContext);
  const [userDetails, setUserDetails] = stateUser;

  const submitSeapodDetails = async (event) => {
    event.preventDefault();
    const json = JSON.stringify({
      SeaPodID: seapodId,
      SeaPodName: seapodName,
      ZoneName: zoneName,
      OccupancyStatus: occupancyState,
      RemoteURL: remoteURL,
      OwnedBy: ownedBy,
    });

    await axios
      .post(
        "https://yb4lptrfk9.execute-api.us-east-1.amazonaws.com/dev",
        json,
        {
          headers: {
            "x-auth-token": userDetails.signInUserSession.idToken.jwtToken,
          },
        }
      )
      .then((res) => {
        setSuccess("Saved SeaPod Info!");
      })
      .catch((err) => {
        // console.log(err);
        setError(err);
      });
  };

  useEffect(() => {
    // https://wraept9ssh.execute-api.us-east-1.amazonaws.com/dev
    // https://vn42a4sduk.execute-api.us-east-1.amazonaws.com/prod 
    axios
      .get("https://wraept9ssh.execute-api.us-east-1.amazonaws.com/dev", {
        headers: {
          "x-auth-token": userDetails.signInUserSession.idToken.jwtToken,
        },
      })
      .then((res) => {
        console.log(res);
        res = JSON.parse(res.data.body);
        res["Users"].map((item) => {
          setUsers((prev) => [
            ...prev,
            {
              id: item["Username"],
              name: item["Attributes"][0]["Value"],
              family_name: item["Attributes"][1]["Value"],
              email: item["Attributes"][2]["Value"],
            },
          ]);
        });
      })
      .catch((err) => console.log(err));
  }, []);
  return (
    <div>
      <Typography variant='h5' style={{ fontWeight: 700, display: "flex", alignItems: "center", justifyContent: "center"  }}>
        Register New SeaPod
      </Typography>
      <form className={classes.form} onSubmit={submitSeapodDetails} autoComplete='off'>
        <div className={classes.textField}>
          <TextField
            id='seapod-name'
            label='SeaPod Name'
            variant='outlined'
            size='small'
            required
            className={classes.textFieldInput}
            InputProps={{ className: classes.input }}
            onChange={(e) => setSeapodName(e.target.value)}
          />
        </div>
        <div className={classes.textDropDown}>
          <FormControl variant='outlined' className={classes.formControl}>
            <InputLabel>Occupancy Status</InputLabel>
            <Select
              id='occupancy-status'
              style={{
                borderRadius: 10,
                backgroundColor: "white",
              }}
              required
              value={occupancyState}
              onChange={(e) => setOccupancyState(e.target.value)}
              label='Country'>
              <MenuItem key='1' value='active'>
                Active/Occupied
              </MenuItem>
              <MenuItem key='1' value='inactive'>
                InActive/Un-occupied
              </MenuItem>
              <MenuItem key='1' value='uninitiated'>
                Uninitiated
              </MenuItem>
            </Select>
          </FormControl>
        </div>
        <div className={classes.textField}>
          <TextField
            id='remote-url'
            label='Remote Access URL'
            variant='outlined'
            size='small'
            required
            className={classes.textFieldInput}
            InputProps={{ className: classes.input }}
            onChange={(e) => setRemoteURL(e.target.value)}
          />
        </div>
        <div className={classes.textDropDown}>
          <FormControl variant='outlined' className={classes.formControl}>
            <InputLabel>Zone Name</InputLabel>
            <Select
              id='zone-name'
              style={{
                borderRadius: 10,
                backgroundColor: "white",
              }}
              required
              defaultValue={zoneName}
              value={zoneName}
              onChange={(e) => setZoneName(e.target.value)}
              label='SeaPod Zone'>
              <MenuItem value={"Panama"}>Panama</MenuItem>
              <MenuItem value={"Dubai"}>Dubai</MenuItem>
              <MenuItem value={"Miami"}>Miami</MenuItem>
            </Select>
          </FormControl>
        </div>
        <div className={classes.textDropDown}>
          <FormControl variant='outlined' className={classes.formControl}>
            <InputLabel>Assign Owner</InputLabel>
            <Select
              id='owner'
              style={{
                borderRadius: 10,
                backgroundColor: "white",
              }}
              required
              value={ownedBy}
              onChange={(e) => setOwnedBy(e.target.value)}
              label='SeaPod Zone'>
              {users.map((i) => (
                <MenuItem value={i["id"]}>
                  {i["name"]} {i["family_name"]}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
        {success !== "" && (
          <div style={{ marginBottom: theme.spacing(2) }}>
            <Alert severity='success'>{success}</Alert>
          </div>
        )}
        {error !== "" && (
          <div style={{ marginBottom: theme.spacing(2) }}>
            <Alert severity='error'>{error}</Alert>
          </div>
        )}

        <div className={classes.firstNextButton}>
          <Button
            id='submit'
            type='submit'
            variant='contained'
            color='primary'
            style={{ borderRadius: "10px" }}
            >
            Register
          </Button>
        </div>
      </form>
    </div>
  );
}

export default AddSeaPod;
