/* eslint-disable */

import React from "react";
import ReactDOM from "react-dom";
import { ThemeProvider, createTheme } from "@material-ui/core/styles";
import App from "./App";
import Amplify from "aws-amplify";
import config from "./config.json";
Amplify.configure({
  Auth: {
    mandatorySignIn: true,
    region: config.cognito.REGION,
    userPoolId: config.cognito.USER_POOL_ID,
    userPoolWebClientId: config.cognito.APP_CLIENT_ID,
  },
  "aws_project_region": config.aws_project_region,
  "aws_appsync_graphqlEndpoint": config.aws_appsync_graphqlEndpoint,
  "aws_appsync_region": config.aws_appsync_region,
  "aws_appsync_authenticationType": config.aws_appsync_authenticationType,
  "aws_appsync_apiKey": config.aws_appsync_apiKey
});

const theme = createTheme({
  typography: {
    fontFamily: ["Poppins"],
  },
  palette: {
    primary: {
      // Purple and green play nicely together.
      main: "#2e74b4",
      light: "#ffff",
    },
    secondary: {
      light: "#CCD2DC", 
      main: '#8B96A9',
    },
    cards: {
      main: "#B7C6DA",
    },
    background: {
      main: "#faf9fe",
    },
    alerts:{
      main: "#f44336",
      light: "#ff9800"
    }
  }
});

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <App />
    </ThemeProvider>
  </React.StrictMode>,

  document.getElementById("root")
);
