export const onAddSeaPodData = `subscription MySubscription {
    onAddSeaPodData{
      Latitude
      Longitude
      PowerStored
      SeaPodID
      SolarPowerProduction
      Timestamp
      Watertank1
      Watertank2
      Watertank3
      ZoneName
      Date
      Time
    }
  }`

  export const onAddAlert = `
  subscription MySubscription {
    onAddAlert {
      SeaPodID
      DeviceName
      Measurement
      DeviceID
      AlertID
      AlertStatus
      Type
      Timestamp
    }
  }
  
  `