import { alpha, makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  appbar: {
    backgroundColor: theme.palette.primary.light,
    zIndex: theme.zIndex.drawer + 1,
  },
  toolbar: {
    [theme.breakpoints.up("md")]: {
      padding: "0px",
    },
    display: "flex",
    justifyContent: "space-between",
  },
  menuDiv: {
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  logo: {
    [theme.breakpoints.down("sm")]: {
      // display: "flex",
      display: (props) => (props.open ? "none" : "flex"),
      // alignItems: "center",
      // justifyContent: "center",
    },
  },
  logoImgLg: {
    marginLeft: "15px",
    marginBottom: "10px",
    cursor: "pointer",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  logoImgSm: {
    marginLeft: "15px",
    marginBottom: "10px",
    marginTop: "10px",
    marginLeft: 0,
    cursor: "pointer",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },

  },
  navbarRight: {
    display: "flex",
    justifyContent: "flex-end",
  },
  search: {
    color: theme.palette.secondary.main,
    display: "flex",
    alignItems: "center",
    borderRadius: "5px",
    padding: "5px",
    backgroundColor: alpha(theme.palette.secondary.main, 0.15),
    "&:hover": {
      backgroundColor: alpha(theme.palette.secondary.light, 0.25),
    },

    width: "100%",
    [theme.breakpoints.down("sm")]: {
      display: (props) => (props.open ? "flex" : "none"),
      width: "70%",
      justifyContent: "space-between",
    },
  },
  cancel: {
    color: theme.palette.secondary.main,
    marginRight: theme.spacing(1),
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  notificationIcon: {
    color: theme.palette.secondary.main,
    cursor: "pointer"
  },
  menu: {
    color: theme.palette.secondary.main,
  },
  icons: {
    alignItems: "center",
    display: (props) => (props.open ? "none" : "flex"),
  },
  searchButton: {
    color: theme.palette.secondary.main,
    marginRight: theme.spacing(2),
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  avatar: {
    marginLeft: "10px",
    height: "56",
    width: "56",
    marginRight: "15px",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
}));
