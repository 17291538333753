import React, { useContext, useEffect, useRef, useState } from "react";
import L from "leaflet";
import "./styles/zoneMap.css";
import MapPointer from "../images/map-point.png";
import Circle from "../images/circle.png";
import { AuthContext } from "../AuthContext";
import config from "../config.json";

function ZoneMap() {
  const { stateMergedSeapodsData } = useContext(AuthContext);
  const [markerPositions, setMarkerPositions] = useState([]);
  const [mergedSeapodsData, setMergedSeapodsData] = stateMergedSeapodsData;
  // console.log(mergedSeapodsData);

  const mapRef = useRef(null);

  var getCentroid = function (arr) {
    return arr.reduce(
      function (x, y) {
        return [x[0] + y[0] / arr.length, x[1] + y[1] / arr.length];
      },
      [0, 0]
    );
  };

  const KtoC = (val) => {
    return Math.round(val - 273.15);
  };

  const CtoF = (val) => {
    return Math.round(val * 1.8 + 32);
  };
  const MStoKMPH = (val) => {
    return Math.round(val * 3.6);
  };

  useEffect(() => {
    mergedSeapodsData.map((item) => {
      if (item.Latitude && item.Longitude) {
        if (item.Latitude !== "unknown" && item.Longitude !== "unknown") {
          setMarkerPositions((state) => [
            ...state,
            [item.Latitude, item.Longitude, item.SeaPodName, item.SeaPodID],
          ]);
        }
      }
    });
    var centroid = getCentroid(markerPositions);
    mapRef.current = L.map("map", {
      center: centroid,
      zoom: 10,
      layers: [
        L.tileLayer("http://{s}.tile.osm.org/{z}/{x}/{y}.png", {
          attribution:
            '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
        }),
      ],
    });
  }, []);

  const markerRef = useRef(null);
  const customIcon1 = L.icon({
    iconUrl: MapPointer,
    iconSize: [45, 45],
  });
  const customIcon2 = L.icon({
    iconUrl: Circle,
    iconSize: [40, 40],
  });

  var popup = L.popup();
  function onMarkerClick(e) {
    console.log(e);
    popup
      .setLatLng(e.latlng)
      .setContent("Fetching weather info")
      .openOn(mapRef.current);

    fetch(
      "https://api.openweathermap.org/data/2.5/weather?lat=" +
        e.latlng.lat +
        "&lon=" +
        e.latlng.lng +
        "&appid=" +
        config.OpenWeatherMap.API_KEY
    )
      .then((r) => r.json())
      .then((data) => {
        console.log(data);
        // Change this line to show exactly the info you need
        // data.weather.map((w) => w.description).join(", ")
        var temp = KtoC(data.main.temp);
        var feelsLike = KtoC(data.main.feels_like);
        var speed = MStoKMPH(data.wind.speed);
        var url = `http://openweathermap.org/img/wn/${data.weather[0].icon}@2x.png`;
        popup.setContent(
          `<h2><b>Weather</b></h2><h3>${data.weather[0].main} <img src=${url} alt="weather icon"></h3><span><b>Weather Desc:</b> ${data.weather[0].description}<br/><b>Temperature:</b> ${temp}°C<br/><b>Feels Like:</b> ${feelsLike}°C<br/><b>Humidity:</b> ${data.main.humidity}%<br/><b>Pressure:</b> ${data.main.pressure}hPa<br/><b>Wind Speed:</b> ${speed}Km/h<br/><b>Lat:</b> ${data.coord.lat}<br/><b>Lon:</b> ${data.coord.lon}</span>`
        );
      });
  }

  useEffect(() => {
    if (mapRef.current !== null) {
      for (var points = 0; points < markerPositions.length; points++) {
        markerRef.current = new L.marker(
          [markerPositions[points][0], markerPositions[points][1]],
          { icon: customIcon1 }
        )
          .addTo(mapRef.current)
          .on("click", onMarkerClick);

        // .bindPopup(
        //   `<h2>${markerPositions[points][2]}</h2><br/><span>SeaPod ID: ${markerPositions[points][3]}</span>`
        // );
      }
      var centroid = getCentroid(markerPositions);
      mapRef.current.flyTo(centroid, 10, { duration: 2 });
      var circleOptions = {
        color: "blue",
        weight: 1,
      };
      var circle = L.circle(centroid, 30000, circleOptions);
      circle.addTo(mapRef.current);
    }
  }, [markerPositions, customIcon1, customIcon2]);

  return (
    <div className='leaflet-container'>
      <div id='map'></div>
    </div>
  );
}

export default ZoneMap;
