import { S3Client, PutObjectCommand } from "@aws-sdk/client-s3";
import config from "../../config.json";

const S3_BUCKET = "notifications-images-admin-panel-dev";
const REGION = "us-east-1";

const myBucket = new S3Client({
  params: { Bucket: S3_BUCKET },
  region: REGION,
  credentials: {
    accessKeyId: config.s3_bucket_notifications_images_admin_panel_aws_access_key_id,
    secretAccessKey: config.s3_bucket_notifications_images_admin_panel_aws_secret_access_key
  }
});

const uploadFile = async (file) => {
  
  const fileExt = file.name.split(".").pop();
  const params = {
    ACL: "public-read",
    Body: file,
    Bucket: S3_BUCKET,
    Key: file.name,
    ContentType: `img/${fileExt}` // extract file extension from file name
  };

  try {
    await myBucket.send(new PutObjectCommand(params));
  } catch (error) {
    console.log(error);
  }
};

export default uploadFile;
