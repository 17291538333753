import React, { useContext } from "react";
import { useHistory } from "react-router-dom";
import { AuthContext } from "../AuthContext";
import { useStyles } from "./styles/styles";
import { Grid, Typography } from "@material-ui/core";

function PasswordChanged() {
  const classes = useStyles();
  const { stateForm } = useContext(AuthContext);
  const [formState, setFormState] = stateForm;
  const history = useHistory();
  return (
    <Grid container className={classes.container}>
      <Grid item xs={12}>
      <div className={classes.logo}>
            <img
              src={require("../images/logo.png")}
              alt='logo'
              className={classes.logoImgLg}
                onClick={() => history.push("/")}
            />
            <img
              src={require("../images/logo-mobile.png")}
              alt='logo'
              className={classes.logoImgSm}
                onClick={() => history.push("/")}
            />
          </div>
      </Grid>
      {formState === "passwordChanged" ? (
        <Grid item xs={12} className={classes.formContainer}>
        <div className={classes.item}>
          <Typography variant="h6" style={{fontWeight:500}}>Your password has been successfully updated!</Typography>
        </div>
        </Grid>
      ) : (
        history.push("/")
      )}
    </Grid>
  );
}

export default PasswordChanged;
