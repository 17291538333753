import React, { useCallback, useContext, useEffect, useState } from "react";
import { AuthContext } from "../AuthContext";
import {
  DataGrid,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarFilterButton,
} from "@mui/x-data-grid";
import axios from "axios";
import { useStyles } from "./styles/styles";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import SaveIcon from "@material-ui/icons/Save";
import AddIcon from "@material-ui/icons/Add";
import { Collapse, MenuItem, Select } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { useTheme } from "@material-ui/styles";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import CloseIcon from "@material-ui/icons/Close";
import DeleteIcon from "@material-ui/icons/Delete";
import {renderSelectEditCell} from "./SelectFieldOccupancy";

var age = "";

const columns = [
  { field: "id", headerName: "SeaPod ID", width: 300, hide: true },
  {
    field: "SeaPodName",
    headerName: "SeaPod Name",
    width: 200,
    editable: true,
  },

  {
    field: "RemoteURL",
    headerName: "Remote URL",
    width: 200,
    editable: true,
    renderCell: (params) => (
      <a href={params.value} target='_blank'>
        {params.value}
      </a>
    ),
  },
  {
    field: "ZoneName",
    headerName: "Zone Name",
    width: 200,
    editable: true,
  },
  {
    field: "OwnedBy",
    headerName: "Owned By",
    width: 200,
    hide: true,
  },
  {
  field: "OccupancyStatus",
  headerName: "Occupancy Status",
  width: 200,
  editable: true,
  renderEditCell: renderSelectEditCell
}

];

function objectsEqual(o1, o2) {
  return JSON.stringify(o1) === JSON.stringify(o2);
}

let rows = [];

function SeaPodsList() {
  const classes = useStyles();
  const [success, setSuccess] = useState(false);
  const [open, setOpen] = useState(true);
  const [dialog, setDialog] = useState(false);
  const [dialogError, setDialogError] = useState(false);
  const { stateUser, stateSidebar, stateAllSeapods } = useContext(AuthContext);
  const [userDetails, setUserDetails] = stateUser;
  const [allSeapods, setAllSeapods] = stateAllSeapods;
  const [seapodName, setSeapodName] = useState("");
  const [allSeapodsCopy, setAllSeapodsCopy] = useState({});
  const [paramsValue, setParamsValue] = useState({});
  const [updatedObj, setUpdatedObj] = useState({});
  const [selection, setSelection] = useState({});
  const [sidebarState, setSidebarState] = stateSidebar;
  const [showSaveButton, setShowSaveButton] = useState(false);
  const [showDeleteButton, setShowDeleteButton] = useState(false);
  const theme = useTheme();

  // const [editRowsModel, setEditRowsModel] = useState({});

  // const handleEditRowModelChange = useCallback((params) => {
  //   setEditRowsModel(params.model);
  // }, []);

  const handleBlur = useCallback((params, event) => {
    event.stopPropagation();
  }, []);

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector />

        {showDeleteButton && (
          <Tooltip title='Delete Seapod'>
            <IconButton
              aria-label='delete seapod'
              size='large'
              color='primary'
              onClick={() => setDialog(true)}>
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        )}
        {showSaveButton && (
          <Tooltip title='Save SeaPod Details'>
            <IconButton
              aria-label='save seapod details'
              size='large'
              color='primary'
              onClick={() => updateSeapod()}>
              <SaveIcon />
            </IconButton>
          </Tooltip>
        )}
      </GridToolbarContainer>
    );
  }

  const updateSeapod = async () => {
    await axios
      .put(
        "https://yb4lptrfk9.execute-api.us-east-1.amazonaws.com/dev",
        updatedObj,
        {
          headers: {
            "x-auth-token": userDetails.signInUserSession.idToken.jwtToken,
          },
        }
      )
      .then((res) => {
        setSuccess(true);
        setShowSaveButton(false);
        const allSeapodsObj = allSeapods.find(
          (item) => item.SeaPodID === paramsValue.id
        );
        Object.keys(allSeapodsObj).forEach((item) => {
          if (item === paramsValue.field) {
            if (allSeapodsObj[item] !== paramsValue.value) {
              allSeapodsObj[item] = paramsValue.value;
            }
          }
        });
      })
      .catch((err) => console.log(err));
  };

  const deleteSeapod = async () => {
    const selectedObj = {
      SeaPodID: selection[0].id,
      ZoneName: selection[0].ZoneName,
    };

    if (seapodName === selection[0].SeaPodName) {
      fetch("https://yb4lptrfk9.execute-api.us-east-1.amazonaws.com/dev", {
        method: "DELETE",
        headers: {
          "x-auth-token": userDetails.signInUserSession.idToken.jwtToken,
        },
        body: JSON.stringify(selectedObj),
      })
        .then((res) => {
          setDialog(false);
        })
        .catch((err) => console.log(err));
    } else {
      setOpen(true);
      setDialogError(true);
    }
  };

  const getSeapodsData = async () => {
    await axios
      .get("https://yb4lptrfk9.execute-api.us-east-1.amazonaws.com/dev", {
        headers: {
          "x-auth-token": userDetails.signInUserSession.idToken.jwtToken,
        },
      })
      .then((res) => {
        res = res.data.seapods;
        res.map((item) => {
          rows.push({
            id: item["SeaPodID"],
            SeaPodName: item["SeaPodName"],
            OccupancyStatus: item["OccupancyStatus"],
            RemoteURL: item["RemoteURL"],
            ZoneName: item["ZoneName"],
            OwnedBy: item["OwnedBy"],
          });
        });
        setAllSeapods(JSON.parse(JSON.stringify(res)));
        setAllSeapodsCopy(JSON.parse(JSON.stringify(res)));
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    if (Object.keys(allSeapods).length === 0) {
      getSeapodsData();
    } else {
      setAllSeapodsCopy(JSON.parse(JSON.stringify(allSeapods)));
      rows = [];
      allSeapods.map((item) => {
        rows.push({
          id: item["SeaPodID"],
          SeaPodName: item["SeaPodName"],
          OccupancyStatus: item["OccupancyStatus"],
          RemoteURL: item["RemoteURL"],
          ZoneName: item["ZoneName"],
          OwnedBy: item["OwnedBy"],
        });
      });
    }
  }, []);
  return (
    <div>
      {Object.keys(selection).length > 0 && (
        <Dialog open={dialog} onClose={() => setDialog(false)}>
          <DialogTitle>Delete SeaPod</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Do you really want to delete SeaPod? Enter the SeaPod Name:{" "}
              <b>
                <em>{selection[0].SeaPodName}</em>
              </b>
              . This action is not reversible.
            </DialogContentText>
            <TextField
              margin='dense'
              // id='name'
              label='Seapod Name'
              required
              fullWidth
              onChange={(e) => setSeapodName(e.target.value)}
              variant='outlined'
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setDialog(false)}>Cancel</Button>
            <Button onClick={() => deleteSeapod()}>Delete</Button>
          </DialogActions>
          {dialogError && (
            <div>
              <Collapse in={open}>
                <Alert
                  style={{
                    marginBottom: theme.spacing(2),
                    marginTop: theme.spacing(2),
                  }}
                  action={
                    <IconButton
                      aria-label='close'
                      color='inherit'
                      size='small'
                      onClick={() => {
                        setOpen(false);
                      }}>
                      <CloseIcon fontSize='inherit' />
                    </IconButton>
                  }
                  severity='error'>
                  Please enter correct SeaPod Name!
                </Alert>
              </Collapse>
            </div>
          )}
        </Dialog>
      )}
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
        }}>
        {/* {showDeleteButton && (
          <Tooltip title='Delete Seapod'>
            <IconButton
              aria-label='delete seapod'
              size='large'
              color='primary'
              onClick={() => setDialog(true)}>
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        )}
        {showSaveButton && (
          <Tooltip title='Save SeaPod Details'>
            <IconButton
              aria-label='save seapod details'
              size='large'
              color='primary'
              onClick={() => updateSeapod()}>
              <SaveIcon />
            </IconButton>
          </Tooltip>
        )} */}
        <Tooltip title='Add new SeaPod'>
          <IconButton
            id='add'
            aria-label='add new seapod'
            size='large'
            color='primary'
            onClick={() => setSidebarState("add_seapod")}>
            <AddIcon />
          </IconButton>
        </Tooltip>
      </div>

      <div style={{ width: "100%" }} id='data-grid'>
        {Object.keys(rows).length !== 0 && (
          <DataGrid
            rows={rows}
            columns={columns}
            autoHeight
            disableSelectionOnClick
            checkboxSelection
            pageSize={5}
            rowsPerPageOptions={[5]}
            components={{
              Toolbar: CustomToolbar,
            }}
            // editRowsModel={editRowsModel}
            // onEditRowModelChange={handleEditRowModelChange}
            onCellBlur={handleBlur}
            onSelectionModelChange={(ids) => {
              const selectedIDs = new Set(ids);
              const selectedRowData = rows.filter((row) =>
                selectedIDs.has(row.id.toString())
              );
              setSelection(selectedRowData);
              setShowDeleteButton(false);
              if (Object.keys(selectedRowData).length !== 0) {
                setShowDeleteButton(true);
              }
            }}
            onCellEditCommit={(params, event) => {
              setShowSaveButton(false);
              setParamsValue(params);
              const searchObj = allSeapodsCopy.find(
                (item) => item.SeaPodID === params.id
              );
              setUpdatedObj(searchObj);
              Object.keys(searchObj).forEach((item) => {
                if (item === params.field) {
                  if (searchObj[item] !== params.value) {
                    searchObj[item] = params.value;
                  }
                }
              });

              if (!objectsEqual(allSeapods, allSeapodsCopy)) {
                setShowSaveButton(true);
              }
            }}
          />
        )}
      </div>
      {success && (
        <div>
          <Collapse in={open}>
            <Alert
              style={{
                marginBottom: theme.spacing(2),
                marginTop: theme.spacing(2),
              }}
              action={
                <IconButton
                  aria-label='close'
                  color='inherit'
                  size='small'
                  onClick={() => {
                    setOpen(false);
                  }}>
                  <CloseIcon fontSize='inherit' />
                </IconButton>
              }
              severity='success'>
              Updated successfully!
            </Alert>
          </Collapse>
        </div>
      )}
    </div>
  );
}

export default SeaPodsList;
