import React, { useContext, useEffect, useState } from "react";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import SeaPodDevices from "./SeaPodDevices";
import { AuthContext } from "../AuthContext";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 140,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const DevicesList = () => {
  const classes = useStyles();
  const { stateAllSeapods, statePodDevices } = useContext(AuthContext);
  const [allSeapods, setAllSeapods] = stateAllSeapods;
  const [seapodID, setSeapodID] = useState("");
  const [seapodName, setSeapodName] = useState("");
  const [podDevices, setPodDevices] = statePodDevices;
  const [selectedPod, setSelectedPod] = useState("");

  const handleChange = (event) => {
    setSelectedPod(event.target.value);
  };

  const handleClose = (id, name) => {
    setSeapodID(id);
    setSeapodName(name);
  };

  useEffect(() => {
    if (Object.keys(podDevices).length !== 0) {
      setSeapodID(podDevices.SeaPodID);
      setSeapodName(podDevices.SeaPodName);
    }
  }, []);

  return (
    <div>
      <FormControl
        // ref={selectDroneComponent}
        variant='outlined'
        className={classes.formControl}>
        <InputLabel id='select-pod-label'>
          {" "}
          {seapodName !== "" ? seapodName : "Select Pod"}
        </InputLabel>
        <Select
          id='select-pod'
          value={selectedPod}
          onChange={handleChange}
          label='pod'>
          {allSeapods.map((seapod) => (
            <MenuItem
              key={seapod.SeaPodID}
              value={seapod.SeaPodName}
              onClick={() => handleClose(seapod.SeaPodID, seapod.SeaPodName)}>
              {seapod.SeaPodName}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      {seapodID !== "" && <SeaPodDevices id={seapodID} />}
    </div>
  );
};

export default DevicesList;
