import { Typography } from "@material-ui/core";
import React from "react";
import {
  BarChart,
  Bar,
  Cell,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

function PowerStored({ mergedData }) {
  return (
    <>
      <Typography variant='h6'>Power Stored in Battery Banks</Typography>

      <ResponsiveContainer width='100%' height='100%'>
        <BarChart width={500} height={200} data={mergedData} id='power-stored'>
          <Tooltip formatter={(label) => label + "KWh"} />
          <Legend />
          <Bar dataKey='PowerStored' barSize={30}>
            {mergedData.map((entry, index) => (
              <Cell
                cursor='pointer'
                fill={
                  entry["PowerStored"] >= 40
                    ? "green"
                    : (entry["PowerStored"] >= 25) & (entry["PowerStored"] < 40)
                    ? "red"
                    : entry["PowerStored"] < 25
                    ? "gray"
                    : "none"
                }
                key={`cell-${index}`}
              />
            ))}
          </Bar>
          <XAxis dataKey='SeaPodName' />
          <YAxis label={{ value: "KWh", angle: -90, position: "insideLeft" }} />
        </BarChart>
      </ResponsiveContainer>
    </>
  );
}

export default PowerStored;
