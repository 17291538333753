import {
  AppBar,
  Toolbar,
  InputBase,
  Avatar,
  Menu,
  MenuItem,
  Tooltip,
  useTheme,
  Badge,
} from "@material-ui/core";
import React, { useContext, useEffect, useState } from "react";
import { Cancel, Search } from "@material-ui/icons";
import { useStyles } from "./styles/navbarStyles";
import MenuIcon from "@material-ui/icons/Menu";
import NotificationsIcon from "@material-ui/icons/Notifications";
import { AuthContext } from "../AuthContext";
import Auth from "@aws-amplify/auth";

function Navbar({ logout }) {
  const [open, setOpen] = useState(false);
  const [userAttrs, setUserAttrs] = useState({});
  const { stateDrawer, stateSidebar, stateNotificationCount } =
    useContext(AuthContext);
  const [drawerState, setDrawerState] = stateDrawer;
  const [sidebarState, setSidebarState] = stateSidebar;
  const [notificationCount, setNotificationCount] = stateNotificationCount;

  const classes = useStyles({ open });
  const theme = useTheme();

  // console.log(userAttrs);
  // For navbar menu
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleBadgeEvent = ()=>{
    setSidebarState("alerts");
  }

  function stringAvatar(name) {
    if (name === undefined) {
      return {
        style: {
          backgroundColor: theme.palette.primary.main,
        },
        children: `Loading`,
      };
    }
    if (name.includes(" ")) {
      return {
        style: {
          backgroundColor: theme.palette.primary.main,
        },
        children: `${name.split(" ")[0][0]}${name.split(" ")[1][0]}`,
      };
    } else {
      return {
        style: {
          backgroundColor: theme.palette.primary.main,
        },
        children: `${name[0]}`,
      };
    }
  }

  useEffect(() => {
    async function getUserInfo() {
      try {
        const userInfo = await Auth.currentAuthenticatedUser();
        setUserAttrs(userInfo.attributes);
        // console.log(userInfo);
      } catch (error) {
        console.log(error);
      }
    }
    getUserInfo();
  }, []);
  return (
    <div>
      <AppBar position='fixed' className={classes.appbar}>
        <Toolbar className={classes.toolbar}>
          <div className={classes.menuDiv}>
            <MenuIcon
              className={classes.menu}
              fontSize='large'
              onClick={() => {
                if (drawerState === false) {
                  setDrawerState(true);
                } else if (drawerState === true) {
                  setDrawerState(false);
                }
              }}
            />
          </div>
          <div className={classes.logo}>
            <img
              id='logoLg'
              src={require("../images/logo.png")}
              alt='logo'
              className={classes.logoImgLg}
            />
            <img
              id='logoSm'
              src={require("../images/logo-mobile.png")}
              alt='logo'
              className={classes.logoImgSm}
            />
          </div>
          <div className={classes.navbarRight}>
            <div className={classes.search}>
              <Search fontSize='large' />
              <InputBase
                placeholder='Search...'
                style={{ marginLeft: "5px" }}
              />
              <Cancel
                className={classes.cancel}
                fontSize='large'
                onClick={() => setOpen(false)}
              />
            </div>
            <div className={classes.icons}>
              <Search
                className={classes.searchButton}
                fontSize='large'
                onClick={() => setOpen(true)}
              />
              <div>
                <Badge color='primary' badgeContent={notificationCount} onClick={handleBadgeEvent}>
                  <div className={classes.notificationIcon}>
                    <NotificationsIcon fontSize='large' />
                  </div>
                </Badge>
              </div>
              <div className={classes.avatar}>
                <Tooltip title={userAttrs.name} aria-label='name'>
                  <Avatar
                    {...stringAvatar(userAttrs.name)}
                    fontSize='large'
                    onClick={handleClick}
                    alt={userAttrs.name}
                  />
                </Tooltip>
              </div>
              <Menu
                id='simple-menu'
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}>
                <MenuItem onClick={() => setSidebarState("preferences")}>
                  My Preferences
                </MenuItem>
                <MenuItem onClick={logout}>Logout</MenuItem>
              </Menu>
            </div>
          </div>
        </Toolbar>
      </AppBar>
    </div>
  );
}

export default Navbar;
