import React, { useEffect, useState, useContext } from "react";
import { FormControl, InputLabel, MenuItem, Select } from "@material-ui/core";

function SelectDrone({
  selectedDrone,
  handleChangeDrone,
  droneIDs,
  formControl,
}) {

  return (
    <FormControl variant='outlined' className={formControl}>
      <InputLabel id='select-drone-label'>Select Drone</InputLabel>
      <Select
        id='select-drone'
        value={selectedDrone}
        onChange={handleChangeDrone}
        label='drone'>
        <MenuItem value=''>
          <em>None</em>
        </MenuItem>
        {droneIDs !== null &&
          droneIDs.map((drone) => (
            <MenuItem key={drone.DroneID} value={drone.DroneID}>
              {drone.DroneID}
            </MenuItem>
          ))}
      </Select>
    </FormControl>
  );
}

export default SelectDrone;
