import {
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Input,
  makeStyles,
  MenuItem,
  Select,
  TextField,
  Typography,
  useTheme,
} from "@material-ui/core";
import axios from "axios";
import React, { useContext, useEffect, useRef, useState } from "react";
import { AuthContext } from "../AuthContext";
import DropzoneComponent from "./DropzoneComponent";
import uploadFile from "./s3/uploadToS3";
import { v4 as uuidv4 } from "uuid";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    maxWidth: 300,
  },
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  chip: {
    margin: 2,
  },
  noLabel: {
    marginTop: theme.spacing(3),
  },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(name, podName, theme) {
  return {
    fontWeight:
      podName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

const AddNewNotification = ({
  open,
  setOpen,
  setAlertBox,
  setOpenAlertBox,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const { stateUser, stateAllSeapods } = useContext(AuthContext);
  const [allSeapods, setAllSeapods] = stateAllSeapods;
  const [userDetails, setUserDetails] = stateUser;
  const [selectedFile, setSelectedFile] = useState(null);
  const [podName, setPodName] = useState([]);
  const [podID, setPodID] = useState([]);
  const [title, setTitle] = useState("");
  const [imgUrl, setImgUrl] = useState("");
  const imgUrlRef = useRef(null);
  const [notificationID, setNotificationID] = useState("");
  const [message, setMessage] = useState("");
  const [action, setAction] = useState("");
  const [draftFound, setDraftFound] = useState(false);
  const [autoFill, setAutoFill] = useState(false);
  const [published, setPublished] = useState(false);
  const [notificationDraft, setNotificationDraft] = useState(null);

  function handleClose() {
    setOpen(false);
  }

  const handleChange = (event) => {
    setPodName(event.target.value);
  };

  const handleMenuItem = (event) => {
    var menuItem = event.target.getAttribute("name");
    if (podID.includes(menuItem)) {
      setPodID(podID.filter((item) => item !== menuItem));
    } else {
      setPodID(() => [...podID, menuItem]);
    }
  };

  const handleChangeMultiple = (event) => {
    const { options } = event.target;
    const value = [];
    for (let i = 0, l = options.length; i < l; i += 1) {
      if (options[i].selected) {
        value.push(options[i].value);
      }
    }
    setPodName(value);
  };
  const getPodIDs = () => {
    for (var i = 0; i < podName.length; i++) {
      for (var j = 0; j < allSeapods.length; j++) {
        if (podName[i] === allSeapods[i].SeaPodName) {
          if (!podID.includes(allSeapods[i].SeaPodID)) {
            setPodID([...podID, allSeapods[i].SeaPodID]);
          }
        }
      }
    }
  };

  const handleFileUpload = async () => {
    let fileUrl;
    // upload selected file to S3
    await uploadFile(selectedFile);
    fileUrl = (
      "https://notifications-images-admin-panel-dev.s3.amazonaws.com/" +
      selectedFile.name
    ).replace(/ /g, "+"); // replace empty spaces in file names with + to compose s3 URL
    imgUrlRef.current = fileUrl;
    setImgUrl(fileUrl);

  };

  const saveDraft = () => {
    if (
      notificationID !== "" &&
      title !== "" &&
      message !== "" &&
      action !== "" &&
      podID.length !== 0
    ) {
      // console.log(imgUrl);
      const draft = {
        NotificationID: notificationID,
        Title: title,
        Message: message,
        FileUrl: imgUrl,
        Action: action,
        DeliveredTo: podID,
        PodNames: podName,
      };
      localStorage.setItem("NotificationDraft", JSON.stringify(draft));
      console.log("saved draft");
      setOpenAlertBox(true);
      setAlertBox({
        text: "Draft saved!",
        severity: "success",
      });
    }
  };

  const handleDraft = async () => {
    if (selectedFile !== null || undefined) {
      await handleFileUpload();
    } else {
      setImgUrl("Image Not Attached!");
    }
  };

  useEffect(() => {
    if (
      imgUrl !== undefined &&
      imgUrl.includes(
        "https://notifications-images-admin-panel-dev.s3.amazonaws.com/"
      ) &&
      autoFill === false &&
      published === false
    ) {
      saveDraft();
      setNotificationID("");
      setTitle("");
      setMessage("");
      setImgUrl("");
      setAction("");
      setPodID([]);
      setPodName([]);
      setDraftFound(true);
      handleClose();
    }
    if (
      imgUrl.includes("Image Not Attached!") &&
      autoFill === false &&
      published === false
    ) {
      saveDraft();
      setNotificationID("");
      setTitle("");
      setMessage("");
      setImgUrl("");
      setAction("");
      setPodID([]);
      setPodName([]);
      setDraftFound(true);
      handleClose();
    }
  }, [imgUrl]);

  const postToDB = async (
    notificationID,
    title,
    message,
    action,
    deliveredTo
  ) => {
    try {
      let body = {
        NotificationID: notificationID,
        Title: title,
        Message: message,
        FileURL: imgUrlRef.current,
        ActionURL: action,
        DeliveredTo: deliveredTo,
        PublishedBy:
          userDetails.attributes.name +
          " " +
          userDetails.attributes.family_name,
        AllNotifications: "NOTIFY",
      };
      await axios
        .post(
          `https://yb4lptrfk9.execute-api.us-east-1.amazonaws.com/dev/notification`,
          body,
          {
            headers: {
              "x-auth-token": userDetails.signInUserSession.idToken.jwtToken,
            },
          }
        )
        .then((res) => {
          if (res.data.Message === "SUCCESS") {
            handleClose();
            setOpenAlertBox(true);
            setAlertBox({
              text: "Notification has been published!",
              severity: "success",
            });
          }
        });
    } catch (error) {
      console.log(error);
      setOpenAlertBox(true);
      setAlertBox({
        text: "Notification publishing failed!",
        severity: "error",
      });
    }
  };

  const handlePublish = async (e) => {
    e.preventDefault();
    setPublished(true);
    if (selectedFile !== null || undefined) {
      await handleFileUpload();
    }
    // store data in db
    console.log(notificationID);

    await postToDB(notificationID, title, message, action, podID);
    setNotificationID("");
    setTitle("");
    setMessage("");
    setImgUrl("");
    setAction("");
    setPodID([]);
    setPodName([]);
    setDraftFound(false);
    localStorage.removeItem("NotificationDraft");
    handleClose();
  };

  const handleAutoFill = () => {
    setAutoFill(true);
    setNotificationID(notificationDraft.NotificationID);
    setTitle(notificationDraft.Title);
    setMessage(notificationDraft.Message);
    setAction(notificationDraft.Action);
    setImgUrl(notificationDraft.FileUrl);
    setPodID(notificationDraft.DeliveredTo);
    setPodName(notificationDraft.PodNames);
  };

  const handleClearDeleteDraft = () => {
    setNotificationID("");
    setTitle("");
    setMessage("");
    setImgUrl("");
    setAction("");
    localStorage.removeItem("NotificationDraft");
    setNotificationDraft({});
    setPodID([]);
    setPodName([]);
    setAutoFill(false);
    setDraftFound(false);
  };

  useEffect(() => {
    setPodName([]);
    setPodID([]);
    setTitle("");
    setMessage("");
    setImgUrl("");
    setPodID([]);
    setPodName([]);

    const getDraft = JSON.parse(localStorage.getItem("NotificationDraft"));
    if (getDraft !== null || undefined) {
      setDraftFound(true);
      setNotificationDraft(getDraft);
    } else {
      let nID = uuidv4();
      setNotificationID(nID);
      setDraftFound(false);
    }
    console.log(getDraft);
  }, [draftFound]);

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">New Notification</DialogTitle>
        <form onSubmit={handlePublish}>
          <DialogContent>
            <TextField
              autoFocus
              margin="dense"
              id="title"
              label="Title"
              type="text"
              value={autoFill ? title !== "" && title : title}
              required
              fullWidth
              onChange={(e) => setTitle(e.target.value)}
            />
            <TextField
              margin="dense"
              id="message"
              label="Message"
              type="text"
              value={autoFill ? message !== "" && message : message}
              multiline
              maxRows={6}
              required
              fullWidth
              onChange={(e) => setMessage(e.target.value)}
            />
            {imgUrl.includes("Image Not Attached!") ||
            imgUrl.includes(
              "https://notifications-images-admin-panel-dev.s3.amazonaws.com/"
            ) ? (
              <Typography
                variant="body2"
                style={{
                  marginTop: "10px",
                  marginBottom: "10px",
                  maxWidth: "320px",
                }}
              >
                {imgUrl}
              </Typography>
            ) : (
              <>
                <Typography
                  variant="body2"
                  style={{ marginTop: "10px", marginBottom: "10px" }}
                >
                  Upload Image
                </Typography>
                <DropzoneComponent setSelectedFile={setSelectedFile} />
              </>
            )}

            <TextField
              margin="dense"
              id="action-url"
              label="Action URL"
              type="text"
              value={autoFill ? action !== "" && action : action}
              required
              fullWidth
              onChange={(e) => setAction(e.target.value)}
            />
            {/* <FormControl className={classes.formControl}> */}
            <div style={{ marginTop: "10px" }}>
              <Typography variant="body2" id="send-to">
                Send to *
              </Typography>
              <Select
                style={{ maxWidth: "404px" }}
                labelId="select-pods-label"
                id="select-pods"
                multiple
                value={podName}
                onChange={handleChange}
                name={podID}
                fullWidth
                required
                input={<Input id="select-multiple-chip" />}
                renderValue={(selected) => (
                  <div className={classes.chips}>
                    {selected.map((value) => (
                      <Chip
                        key={value}
                        label={value}
                        className={classes.chip}
                      />
                    ))}
                  </div>
                )}
                MenuProps={MenuProps}
              >
                {allSeapods.map((pod) => (
                  <MenuItem
                    key={pod.SeaPodID}
                    value={pod.SeaPodName}
                    name={pod.SeaPodID}
                    style={getStyles(pod.SeaPodName, pod.SeaPodName, theme)}
                    onClick={handleMenuItem}
                  >
                    {pod.SeaPodName}
                  </MenuItem>
                ))}
              </Select>
            </div>
            {/* </FormControl> */}
          </DialogContent>

          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Cancel
            </Button>
            {draftFound ? (
              <>
                <Button onClick={handleClearDeleteDraft} color="primary">
                  Clear & Delete Draft
                </Button>
                <Button color="primary" onClick={handleAutoFill}>
                  Auto Fill
                </Button>
              </>
            ) : (
              <Button color="primary" onClick={handleDraft}>
                Save
              </Button>
            )}

            <Button type="submit" color="primary">
              Publish
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
};

export default AddNewNotification;
